import React from "react";
import { Box, Typography, Divider } from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

const Skills = () => {
  return (
    <Box
      sx={{
        height: {
          xs: "auto",
          md: "400px",
        },
        paddingBlock: "10px",
        textAlign: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          position: "relative",
          padding: "20px",
        }}
      >
        <ArrowDownwardIcon
          sx={{
            transform: "rotate(-45deg)",
            fontSize: "30px",
            marginRight: "8px",
          }}
        />
        <Typography
          variant="h6"
          sx={{
            fontWeight: "bold",
            color: "black",
            textTransform: "uppercase",
          }}
        >
          Proficiencies
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          flexDirection: { xs: "row", md: "row" },
          justifyContent: { xs: "center", md: "space-around" },
          alignItems: "flex-start",
          gap: { xs: 3, md: 0 },
          textAlign: "left",
        }}
      >
        {/* Frontend Skills */}
        <Box
          sx={{
            width: { xs: "100%", md: "45%" },
            minWidth: "150px",
            textAlign: "left",
          }}
        >
          <Typography
            variant="h6"
            sx={{ fontWeight: "bold", marginBottom: "10px" }}
          >
            FrontEnd
          </Typography>
          <Typography>
            JavaScript, ReactJS/React Native, TypeScript, PixiJS, Redux, UX/UI,
            MUI, Styled Components, Bootstrap/Tailwind
          </Typography>
        </Box>

        {/* Divider for medium screens and above */}
        <Divider
          orientation="vertical"
          flexItem
          sx={{ display: { xs: "none", md: "block" }, height: "auto" }}
        />

        {/* Backend Skills */}
        <Box
          sx={{
            width: { xs: "100%", md: "45%" },
            minWidth: "150px",
            textAlign: "left",
          }}
        >
          <Typography
            variant="h6"
            sx={{ fontWeight: "bold", marginBottom: "10px" }}
          >
            Backend
          </Typography>
          <Typography>
            Node.js/Express, PassportJs, MongoDB, Django/SQLite, PostgresSQL,
            REST API, Linux environment
          </Typography>
        </Box>

        {/* Divider for medium screens and above */}
        <Divider
          orientation="vertical"
          flexItem
          sx={{ display: { xs: "none", md: "block" }, height: "auto" }}
        />

        {/* Tools/Other Skills */}
        <Box
          sx={{
            width: { xs: "100%", md: "45%" },
            minWidth: "150px",
            textAlign: "left",
          }}
        >
          <Typography
            variant="h6"
            sx={{ fontWeight: "bold", marginBottom: "10px" }}
          >
            Tools/Other
          </Typography>
          <Typography>
            Bash, AWS, Netlify/Render, GitHub, Git, Figma, API Testing: Postman
          </Typography>
        </Box>

        {/* Divider for medium screens and above */}
        <Divider
          orientation="vertical"
          flexItem
          sx={{ display: { xs: "none", md: "block" }, height: "auto" }}
        />

        {/* Language Skills */}
        <Box
          sx={{
            width: { xs: "100%", md: "45%" },
            minWidth: "150px",
            textAlign: "left",
          }}
        >
          <Typography
            variant="h6"
            sx={{ fontWeight: "bold", marginBottom: "10px" }}
          >
            Languages
          </Typography>
          <Typography>
            Russian, Romanian, English, Conversational Swedish
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Skills;

// import React from "react";
// import { Box, Typography, Divider } from "@mui/material";
// import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
// import {
//   SiJavascript,
//   SiReact,
//   SiRedux,
//   SiTypescript,
//   SiBootstrap,
//   SiMaterialui,
//   SiNodedotjs,
//   SiMongodb,
//   SiDjango,
//   SiPostgresql,
//   SiAmazonaws,
//   SiGithub,
//   SiGit,
//   SiFigma,
//   SiPostman,
//   SiLinux,
// } from "react-icons/si";

// const Skills = () => {
//   return (
//     <Box
//       sx={{
//         height: {
//           xs: "auto",
//           md: "400px",
//         },
//         paddingBlock: "10px",
//         textAlign: "center",
//       }}
//     >
//       <Box
//         sx={{
//           display: "flex",
//           justifyContent: "flex-start",
//           alignItems: "flex-start",
//           position: "relative",
//           padding: "20px",
//         }}
//       >
//         <ArrowDownwardIcon
//           sx={{
//             transform: "rotate(-45deg)",
//             fontSize: "30px",
//             marginRight: "8px",
//           }}
//         />
//         <Typography
//           variant="h6"
//           sx={{
//             fontWeight: "bold",
//             color: "black",
//             textTransform: "uppercase",
//           }}
//         >
//           Proficiencies
//         </Typography>
//       </Box>

//       <Box
//         sx={{
//           display: "flex",
//           flexWrap: "wrap",
//           flexDirection: { xs: "row", md: "row" },
//           justifyContent: { xs: "center", md: "space-between" },
//           alignItems: "flex-start",
//           gap: { xs: 3, md: 0 },
//           textAlign: "left",
//         }}
//       >
//         {/* Frontend Skills */}
//         <Box
//           sx={{
//             width: { xs: "100%", md: "23%" },
//             minWidth: "180px",
//             textAlign: "left",
//           }}
//         >
//           <Typography
//             variant="h6"
//             sx={{ fontWeight: "bold", marginBottom: "10px" }}
//           >
//             FrontEnd
//           </Typography>
//           <Typography>
//             <SiJavascript color="#F7DF1E" /> JavaScript
//           </Typography>
//           <Typography>
//             <SiReact color="#61DAFB" /> ReactJS/React Native
//           </Typography>
//           <Typography>
//             <SiTypescript color="#007ACC" /> TypeScript
//           </Typography>
//           <Typography>
//             <SiRedux color="#764ABC" /> Redux
//           </Typography>
//           <Typography>
//             <SiMaterialui color="#007FFF" /> MUI
//           </Typography>
//           <Typography>
//             <SiBootstrap color="#7952B3" /> Bootstrap
//           </Typography>
//         </Box>

//         <Divider
//           orientation="vertical"
//           flexItem
//           sx={{ display: { xs: "none", md: "block" }, height: "auto" }}
//         />

//         {/* Backend Skills */}
//         <Box
//           sx={{
//             width: { xs: "100%", md: "23%" },
//             minWidth: "180px",
//             textAlign: "left",
//           }}
//         >
//           <Typography
//             variant="h6"
//             sx={{ fontWeight: "bold", marginBottom: "10px" }}
//           >
//             Backend
//           </Typography>
//           <Typography>
//             <SiNodedotjs color="#339933" /> Node.js/Express
//           </Typography>

//           <Typography>
//             <SiMongodb color="#47A248" /> MongoDB
//           </Typography>
//           <Typography>
//             <SiDjango color="#092E20" /> Django/SQLite
//           </Typography>
//           <Typography>
//             <SiPostgresql color="#336791" /> PostgreSQL
//           </Typography>
//           <Typography>
//             <SiLinux color="#FCC624" /> Linux Environment
//           </Typography>
//         </Box>

//         <Divider
//           orientation="vertical"
//           flexItem
//           sx={{ display: { xs: "none", md: "block" }, height: "auto" }}
//         />

//         {/* Tools/Other Skills */}
//         <Box
//           sx={{
//             width: { xs: "100%", md: "23%" },
//             minWidth: "180px",
//             textAlign: "left",
//           }}
//         >
//           <Typography
//             variant="h6"
//             sx={{ fontWeight: "bold", marginBottom: "10px" }}
//           >
//             Tools/Other
//           </Typography>
//           <Typography>
//             <SiAmazonaws color="#FF9900" /> AWS
//           </Typography>
//           <Typography>
//             <SiGithub color="#181717" /> GitHub
//           </Typography>
//           <Typography>
//             <SiGit color="#F05032" /> Git
//           </Typography>
//           <Typography>
//             <SiFigma color="#F24E1E" /> Figma
//           </Typography>
//           <Typography>
//             <SiPostman color="#FF6C37" /> Postman
//           </Typography>
//         </Box>

//         <Divider
//           orientation="vertical"
//           flexItem
//           sx={{ display: { xs: "none", md: "block" }, height: "auto" }}
//         />

//         {/* Language Skills */}
//         <Box
//           sx={{
//             width: { xs: "100%", md: "23%" },
//             minWidth: "180px",
//             textAlign: "left",
//           }}
//         >
//           <Typography
//             variant="h6"
//             sx={{ fontWeight: "bold", marginBottom: "10px" }}
//           >
//             Language Skills
//           </Typography>
//           <Typography>
//             Russian, Romanian, English, Conversational Swedish
//           </Typography>
//         </Box>
//       </Box>
//     </Box>
//   );
// };

// export default Skills;
