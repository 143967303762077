import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';

const Cursor = () => {
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
  const [dotPosition, setDotPosition] = useState({ x: 0, y: 0 });

  // Update cursor position on mouse move, adjusting for scroll position
  useEffect(() => {
    const handleMouseMove = (e) => {
      setCursorPosition({
        x: e.clientX,
        y: e.clientY + window.scrollY,
      });
    };

    document.addEventListener('mousemove', handleMouseMove);
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  // Smoothly move the dot position towards the cursor position
  useEffect(() => {
    const moveDot = () => {
      setDotPosition((prev) => {
        const dx = cursorPosition.x - prev.x;
        const dy = cursorPosition.y - prev.y;
        return {
          x: prev.x + dx * 0.1, // Adjust the 0.1 factor for slower or faster trailing
          y: prev.y + dy * 0.1,
        };
      });
    };

    const animationFrame = requestAnimationFrame(moveDot);
    return () => cancelAnimationFrame(animationFrame);
  }, [cursorPosition]);

  return (
    <Box
      sx={{
        position: 'absolute',
        width: 20,
        height: 20,
        borderRadius: '50%',
        backgroundColor: 'rgba(19, 18, 18, 0.5)',
        pointerEvents: 'none',
        transform: 'translate(-50%, -50%)',
        left: `${dotPosition.x}px`,
        top: `${dotPosition.y}px`,
        transition: 'background-color 0.2s ease',
        zIndex: 1000,
      }}
    />
  );
};

export default Cursor;

