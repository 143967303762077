// import { Box } from "@mui/material";
// import Hero from "../../components/Hero";
// import Red from "../../components/homeComponents/Red";
// import Second from "../../components/homeComponents/Second";
// import Welcome from "../../components/homeComponents/Welcome";
// import LastCard from "../../components/homeComponents/LastCard";

// function Home() {
//   return (
//     <Box sx={{ height: "auto" }}>
//       <Hero />
//       <Welcome />
//       <Second />
//       <Red sx={{ position: "sticky" }} />
//       <LastCard />
//     </Box>
//   );
// }
// export default Home;

import React from "react";
import { Box } from "@mui/material";
import Hero from "../../components/Hero";
import Red from "../../components/homeComponents/Red";
import Second from "../../components/homeComponents/Second";
import Welcome from "../../components/homeComponents/Welcome";
import Services from "../Services";
import Reviews from "../../components/homeComponents/Reviews";

function Home() {
  return (
    <Box sx={{ height: "auto" }}>
      <Hero />
      <Welcome />
      <Second />
      <Red />
      <Services />
      <Reviews />
    </Box>
  );
}

export default Home;
