import React from "react";

import FAQ from "../../components/FAQ.js";

function About() {
  return (
    <div>
      <FAQ/>
    </div>
  );
}

export default About;
