
export const imageStyles = {
    position: 'relative',
    '& img': {
      width: { xs: '100px', md: 'auto' },    // 100px width on extra small screens, auto on medium and up
      height: { xs: 'auto', md: '100px' },    // auto height on extra small screens, 100px on medium and up
      transition: 'transform 0.5s ease-in-out',
      cursor: 'pointer',
    
    },
  };
  
  export const imageBox = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: -2, // Video in the background
    overflow: 'hidden',
  }

  // Stack images on top of each other 
  export const imagesStack = {
    position: 'absolute',
                bottom: {
                  xs: 'auto',
                  md: 'auto',
                },
                top: {
                  xs: '3%', // Top position for xs
                  md: '10%',
                },
                left: {
                  xs: '2%', // Left position for xs
                  md: '5%',
                },
                display: 'flex',
                flexDirection: {
                  xs: 'column', // Stack images on top of each other on xs
                  md: 'row',    // Row layout for md and above
                },
                justifyContent: {
                  xs: 'flex-start', // Start alignment on small screens
                  md: 'flex-start',
                },
                gap: '10px', 
                overflow: 'hidden',  
  }

  export const linkStyles={
 
    textDecoration: 'none',
    color: 'rgba(255, 255, 255, 0.9)', // Softer white
    fontWeight: 'bold',
    fontSize: '1.1rem', // Slightly larger font size
    padding: '4px 8px', // Padding for better readability
    transition: 'transform 0.3s ease-in-out, color 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
    '&:hover': {
      color: '#c5d7fc', // Gold accent color on hover
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.3)', // Subtle shadow on hover
      transform: 'scale(1.05)', // Slight scaling effect
    },
  
  }