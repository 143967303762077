import React, { useState } from "react";
import { Box, Card } from "@mui/material";
import all1 from "../../assets/proj/image26.png"; // Importing the images
import all2 from "../../assets/proj/image 34.png";
import all3 from "../../assets/proj/image 31.png";
import all4 from "../../assets/proj/image 29.png";
import all5 from "../../assets/proj/image 30.png";
import all6 from "../../assets/proj/image 32.png";

import stad from "../../assets/video/stad.mp4";
import { Fade } from "react-awesome-reveal";
import { imageBox, imagesStack, imageStyles, linkStyles } from "./styles";

const ThirdCard = () => {
  const [hoveredImage, setHoveredImage] = useState(null);

  const handleMouseEnter = (imageNumber) => {
    setHoveredImage(imageNumber);
  };

  const handleMouseLeave = () => {
    setHoveredImage(null); // Reset when mouse leaves
  };

  return (
    <Card
      sx={{
        width: "90%",
        height: "70vh",
        paddingInline: "10px",
        margin: "20px auto",
        position: "sticky",
        top: "150px",
        zIndex: -1,
        boxShadow: "0px 8px 20px rgba(241, 229, 229, 0.3)", // Cool box shadow
        overflow: "hidden", // Ensure the video and gradient stay contained
        marginBottom: "50px",
      }}
    >
      {/* link to website  */}
      <Box
        sx={{
          position: "absolute",
          top: "10px",
          right: "10px",
          textAlign: "left",
          display: "flex",
          flexDirection: "column",
          gap: "12px",
        }}
      >
        <Box
          component="a"
          href="https://rent-stad.netlify.app/"
          target="_blank"
          rel="noopener noreferrer"
          sx={linkStyles}
        >
          Rent-Städ
        </Box>
        <Box
          component="a"
          href="https://svrent.se/"
          target="_blank"
          rel="noopener noreferrer"
          sx={linkStyles}
        >
          SV Rent
        </Box>
        <Box
          component="a"
          href="https://www.viktoriyastad.se/"
          target="_blank"
          rel="noopener noreferrer"
          sx={linkStyles}
        >
          Viktoriyastad
        </Box>
      </Box>

      {/* Images Box */}
      <Box sx={imageBox}>
        <video
          autoPlay
          loop
          muted
          playsInline
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        >
          <source src={stad} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </Box>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(65, 75, 62, 0.95)", // Semi-transparent black to darken the video
          zIndex: -2, // Ensure the overlay is above the video but below content
        }}
      />
      <Box sx={imagesStack}>
        {/* First Image */}
        <Box
          sx={imageStyles}
          onMouseEnter={() => handleMouseEnter(1)} // When hovering over the first image
          onMouseLeave={handleMouseLeave} // When leaving the hover
        >
          <img
            src={all1}
            alt="Anpassat högkvalitativt webbplatsprojekt av Alina Samoteev, levererat snabbt"
          />
        </Box>

        {/* Second Image */}
        <Box
          sx={imageStyles}
          onMouseEnter={() => handleMouseEnter(2)} // Hover triggers visibility of the second set
          onMouseLeave={handleMouseLeave}
        >
          <img
            src={all2}
            alt="Anpassat högkvalitativt webbplatsprojekt av Alina Samoteev, levererat snabbt"
          />
        </Box>

        {/* Third Image */}
        <Box
          sx={imageStyles}
          onMouseEnter={() => handleMouseEnter(3)}
          onMouseLeave={handleMouseLeave}
        >
          <img
            src={all3}
            alt="Ett exempel på en skräddarsydd hemsida i Stockholm med snabb leverans

"
          />
        </Box>

        {/* Fourth Image */}
        <Box
          sx={imageStyles}
          onMouseEnter={() => handleMouseEnter(4)}
          onMouseLeave={handleMouseLeave}
        >
          <img
            src={all4}
            alt="Hög kvalitet och snabb leverans av anpassad hemsida för företag i Stockholm

"
          />
        </Box>

        {/* Fifth Image */}
        <Box
          sx={imageStyles}
          onMouseEnter={() => handleMouseEnter(5)}
          onMouseLeave={handleMouseLeave}
        >
          <img
            src={all5}
            alt="Hög kvalitet och snabb leverans av anpassad hemsida för företag i Stockholm

"
          />
        </Box>

        {/* Sixth Image */}
        <Box
          sx={imageStyles}
          onMouseEnter={() => handleMouseEnter(6)}
          onMouseLeave={handleMouseLeave}
        >
          <img
            src={all6}
            alt="Professionell hemsida utvecklad på kort tid för företag i Stockholm

"
          />
        </Box>
      </Box>

      {/* Second set of images, visible on hover */}
      {hoveredImage && (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",

            zIndex: 20,
          }}
        >
          <Fade delay={100} duration={1000}>
            <img
              width="300px"
              height="auto"
              src={
                hoveredImage === 1
                  ? all1
                  : hoveredImage === 2
                  ? all2
                  : hoveredImage === 3
                  ? all3
                  : hoveredImage === 4
                  ? all4
                  : hoveredImage === 5
                  ? all5
                  : all6
              }
              alt={`Hover ${hoveredImage}`}
            />
          </Fade>
        </Box>
      )}
    </Card>
  );
};

export default ThirdCard;
