import React from "react";
import { Box, Typography } from "@mui/material";
import customerImage from "../../assets/proj/customer.jpg";

const HeroCustomer = () => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "400px", // Adjust height as needed
        backgroundImage: `url(${customerImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        color: "white",
        fontFamily: "Montserrat, sans-serif",
        padding: "20px",
      }}
    >
      {/* Overlay */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.5)", // Dark overlay
          zIndex: 1,
        }}
      />

      {/* Text Content */}
      <Box
        sx={{
          position: "relative",
          zIndex: 2,
        }}
      >
        <Typography
          variant="h2"
          sx={{ fontWeight: "bold", fontSize: "36px", mb: 2 }}
        >
          CUSTOMER CASES 💼
        </Typography>
        <Typography variant="h5" sx={{ fontSize: "20px", fontWeight: "500" }}>
          Discover Inspiring Success Stories and Projects
        </Typography>
      </Box>
    </Box>
  );
};

export default HeroCustomer;
