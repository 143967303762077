import React, { useState } from "react";
import { Box, Typography, IconButton } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import HeroFaq from "./blogComponents/HeroFaq";
import { useLanguage } from "../components/constants/LanguageContext";
import { faqText } from "../components/constants/textCostants";
import UsefulLinks from "./blogComponents/UsefulLinks";

const FAQ = () => {
  const { language } = useLanguage();
  const text = faqText[language] || faqText.en; // Default to English if language is undefined
  const [expanded, setExpanded] = useState(null);

  const handleExpandClick = (id) => {
    setExpanded(expanded === id ? null : id);
  };

  return (
    <>
      <HeroFaq />
      <Box sx={{ maxWidth: "800px", margin: "auto", padding: "100px 40px" }}>
        <Typography
          variant="h4"
          sx={{
            fontWeight: "bold",
            textAlign: "center",
            fontSize: "28px",
            fontFamily: "Montserrat, sans-serif",
            marginBottom: "20px",
          }}
        >
          {text.title}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontSize: "16px",
            fontFamily: "Arial, sans-serif",
            lineHeight: "1.6",
            marginBottom: "20px",
          }}
        >
          {text.introduction}
          <br />
          <br />
          {text.domainHosting}
          <ul>
            <li>
              <strong>{text.domain}</strong>
            </li>
            <li>
              <strong>{text.hosting}</strong>
            </li>
          </ul>
        </Typography>
        <Box
          sx={{
            margin: "auto",
            width: "50px",
            height: "3px",
            backgroundColor: "#122945",
            marginBottom: "16px",
            textAlign: "center",
          }}
        />
        {text.faqItems.map((faq, index) => (
          <Box
            key={index}
            sx={{ marginBottom: "10px", borderBottom: "1px solid #e0e0e0" }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "10px 0",
                cursor: "pointer",
              }}
              onClick={() => handleExpandClick(index)}
            >
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "bold",
                  textAlign: "left",
                  fontSize: "18px",
                  fontFamily: "Montserrat, sans-serif",
                  color: expanded === index ? "#d32f2f" : "#1e2949",
                }}
              >
                {String(index + 1).padStart(2, "0")}. {faq.question}
              </Typography>
              <IconButton>
                {expanded === index ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            </Box>
            {expanded === index && (
              <Box sx={{ paddingLeft: "20px", marginBottom: "10px" }}>
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "14px",
                    fontFamily: "Arial, sans-serif",
                    color: "#333",
                  }}
                >
                  {faq.answer}
                </Typography>
              </Box>
            )}
          </Box>
        ))}

        <Box
          sx={{
            margin: "auto",
            marginBlock: "100px 50px",
          }}
        >
          <UsefulLinks />
        </Box>
      </Box>
    </>
  );
};

export default FAQ;
