import React from 'react';
import { Box, Typography, Divider } from '@mui/material';
import HeroEcommerce from './HeroEcommerce';
import StoreIcon from '@mui/icons-material/Store';
import CloudIcon from '@mui/icons-material/Cloud';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { useLanguage } from "../constants/LanguageContext";
import { ecommerceText } from "../constants/textCostants";

const Ecommerce = () => {
  const { language } = useLanguage();
  const text = ecommerceText[language] || ecommerceText.en;

  return (
    <>  
      <HeroEcommerce /> 
      <Box
        sx={{
          width: '100%',
          maxWidth: { xs: '90%', md: '800px' },
          margin: '0 auto',
          padding: '100px 20px',
          textAlign: 'left',
          fontFamily: 'Montserrat, sans-serif',
          lineHeight: '1.7',
        }}
      >
 <Typography 
  variant="h4" 
  sx={{ 
    fontWeight: 'bold', 
    mb: 3, 
    display: 'flex', 
    alignItems: 'center', 
    gap: 1, 
    flexWrap: 'wrap', 
    wordBreak: 'break-word' 
  }}
>
  <StoreIcon color="primary" /> {text.title}
</Typography>
        
        <Typography variant="body1" sx={{ mb: 4 }}>
          {text.intro}
        </Typography>

        <Divider sx={{ my: 3 }} />

        <Typography variant="h5" sx={{ fontWeight: 'bold', display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
          <CloudIcon color="secondary" /> {text.reactFirebaseTitle}
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }} dangerouslySetInnerHTML={{ __html: text.reactFirebaseDesc }} />
        <Typography variant="body1" sx={{ mb: 3 }}>
          {text.reactFirebasePros}
        </Typography>

        <Divider sx={{ my: 3 }} />

        <Typography variant="h5" sx={{ fontWeight: 'bold', display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
          <CloudIcon color="secondary" /> {text.mernAwsTitle}
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }} dangerouslySetInnerHTML={{ __html: text.mernAwsDesc }} />
        <Typography variant="body1" sx={{ mb: 3 }}>
          {text.mernAwsPros}
        </Typography>

        <Divider sx={{ my: 3 }} />

        <Typography variant="h5" sx={{ fontWeight: 'bold', display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
          <AttachMoneyIcon color="primary" /> {text.costsTitle}
        </Typography>
        <Typography variant="body1" sx={{ mb: 3 }} dangerouslySetInnerHTML={{ __html: text.costsDesc }} />
      </Box>
    </>
  );
};

export default Ecommerce;
