// import React from 'react';
// import { Box, Card } from '@mui/material';

// const Red = () => {
//   return (
//     <Box
//       sx={{
//         position: 'relative',
//         marginTop: '24px',
//         width: '100%',
//         height: '100vh',
//         overflow: 'hidden', // Prevent container from scrolling
//         mx: 'auto',
//       }}
//     >
//       <Box
//         sx={{
//           position: 'relative',
//           height: '100%',
//           width: '100%',
//         }}
//       >
//         {/* Overlay */}
//          <Box
//           sx={{
//             position: 'absolute',
//             zIndex: 1,
//             height: '100%',
//             width: '100%',
//             display: 'flex',
//             justifyContent: 'center',
//             alignItems: 'center',
//             pointerEvents: 'none', // Allow clicks to pass through
//             backgroundColor: 'rgba(255, 255, 255, 0.5)', // Semi-transparent background
//           }}
//         >

//         </Box>

//         {/* Scrollable content */}
//         <Box
//           sx={{
//             overflowY: 'scroll',
//             height: '100%',
//             position: 'relative',
//             zIndex: 0, // Ensures cards are beneath the overlay
//           }}
//         >
//           {/* Red Card */}
//           <Card
//             sx={{
//               width: '80%',
//               height: '70vh',
//               backgroundColor: 'red',
//               margin: '20px auto',
//               border: '3px solid green',
//               borderRadius: '10px',
//               position:'sticky',
//               top:'50px',
//               zIndex:-1,
//             }}
//           />

//           {/* Blue Card */}
//           <Card
//             sx={{
//               width: '85%',
//               height: '70vh',
//               backgroundColor: 'blue',
//               margin: '20px auto',
//               border: '3px solid green',
//               borderRadius: '10px',
//               position:'sticky',
//               top:'150px',
//               zIndex:1,
//             }}
//           />

//           {/* Green Card */}
//           <Card
//             sx={{
//               width: '90%',
//               height: '70vh',
//               backgroundColor: 'green',
//               margin: '20px auto',
//               border: '3px solid green',
//               borderRadius: '10px',
//               position:'sticky',
//               top:'250px',
//               zIndex:2,
//             }}
//           />

//           {/* Purple Card */}
//           <Card
//             sx={{
//               width: '95%',
//               height: '70vh',
//               backgroundColor: 'purple',
//               margin: '20px auto',
//               border: '3px solid green',
//               borderRadius: '10px',
//               position:'sticky',
//               top:'350px',
//               zIndex:3,
//             }}
//           />

//           {/* Orange Card */}
//           <Card
//             sx={{
//               width: '99%',
//               height: '100vh',
//               backgroundColor: 'orange',
//               margin: '20px auto',
//               border: '3px solid green',
//               borderRadius: '10px',
//               position:'sticky',
//               top:'50%',
//               zIndex:4,
//             }}
//           />
//         </Box>
//       </Box>
//     </Box>
//   );
// };

// export default Red;

import { Box } from "@mui/material";

import FirstCard from "./FirstCard";
import SecondCard from "./SecondCard";
import ThirdCard from "./ThirdCard";
import FourthCard from "./FourthCard";

const Red = () => {
  return (
    <Box sx={{ paddingBlock: "100px" }}>
      <Box
        sx={{
          position: "relative",

          marginTop: "1px",
          width: "100%",
          height: "100vh",
          overflow: "hidden", // Prevent container from scrolling
          mx: "auto",
        }}
      >
        <Box
          sx={{
            position: "relative",
            height: "100%",
            width: "100%",
          }}
        >
          {/* Overlay */}
          <Box
            sx={{
              position: "absolute",
              zIndex: 1,
              height: "100%",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              pointerEvents: "none", // Allow clicks to pass through
            }}
          ></Box>

          {/* Scrollable content */}
          <Box
            sx={{
              overflowY: "scroll",
              scrollBehavior: "smooth", // Smooth scrolling effect
              "::-webkit-scrollbar": {
                width: "8px", // Customize scrollbar width
              },
              "::-webkit-scrollbar-thumb": {
                backgroundColor: "#888", // Scrollbar color
              },
              "::-webkit-scrollbar-thumb:hover": {
                backgroundColor: "#555", // Change color on hover
              },
              height: "100%",
              position: "relative",
              zIndex: 0, // Ensures cards are beneath the overlay
            }}
          >
            {/* Red Card with Background and Images */}

            <FourthCard />
            {/* <FirstCard/> */}

            {/* Blue Card */}

            <SecondCard />
            {/* Green Card */}

            <ThirdCard />
            {/* Purple Card */}
            {/* <FourthCard/> */}
            <FirstCard />

            {/* Orange Card */}
            {/* <LastCard/> */}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Red;
