import React from 'react';
import { Box, Link } from '@mui/material';
import { FaLinkedin, FaGithub, FaTiktok, FaFacebook } from 'react-icons/fa'; // Importing social icons
import Contact from '../Contact';

const Thitd = () => {
  return (
    <Box
    sx={{
      width: '100%',
      height: 'auto',
      backgroundColor: '#252525',
      display: 'flex',
      flexDirection: { xs: 'column', md: 'row' },
      alignItems: 'center',
      justifyContent: 'center',
      padding: 2,
   
      textAlign: { xs: 'left', md: 'center' }, // Ensures text is centered on md and above
    }}
  >
    {/* First Column - Contact Form */}
    <Box
      sx={{
        width: { xs: '100%', md: '45%' },
        display: 'flex',
        justifyContent: { xs: 'center', md: 'center' }, // Center the content within the column
      }}
    >
      <Contact />
    </Box>

    {/* Second Column - Social Links */}
    <Box
      sx={{
        width: { xs: '100%', md: '45%' },
        color: 'white',
        display: 'flex',
        justifyContent: 'center', // Centers content within the column
        alignItems: 'center',
      }}
    >
        <Box sx={{ display: 'flex', flexDirection:{
          xs:'inline', md:'column'
        } , gap: 2 , justifyContent:'center'}}>
          <Link href="https://www.linkedin.com/in/alina-samoteev-627836a5/" target="_blank" color="inherit" underline="hover" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <FaLinkedin /> 
          </Link>
          <Link href="https://github.com/AlinaCGM" target="_blank" color="inherit" underline="hover" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <FaGithub /> 
          </Link>
          <Link href="https://www.tiktok.com/@shoutitout_se?_t=8qAsU8viKXI&_r=1" target="_blank" color="inherit" underline="hover" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <FaTiktok /> 
          </Link>
          <Link href="https://www.facebook.com/shoutitout.se/" target="_blank" color="inherit" underline="hover" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <FaFacebook /> 
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default Thitd;

