import React from "react";
import { Box, Typography, Card, CardContent, Avatar } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import { styled } from "@mui/system";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import bg1 from "../../assets/alice.jpg";
import bg2 from "../../assets/14.jpg";
import bg3 from "../../assets/proj/image 48.png";
// Sample review data
const reviewsData = [
  {
    id: 1,
    name: "AgloRedovisning",
    review:
      "Fast delivery and exactly matched all our requirements—highly efficient and reliable service!",
    rating: 5,
    avatar: bg1, // Placeholder avatar URL
  },
  {
    id: 2,
    name: "CarDent",
    review:
      "Excellent communication throughout! The process was smooth and transparent, and we always knew what to expect at each stage.",
    rating: 5,
    avatar: bg2,
  },
  {
    id: 3,
    name: "GRC-Bygg",
    review:
      "Delivered in just 1 day! The speed and quality were beyond impressive—highly recommended!",
    rating: 5,
    avatar: bg3,
  },
];

// Styled components
const ReviewsContainer = styled(Box)({
  padding: "100px  1rem",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  backgroundColor: "#f4f4f4", // Light background
});

const ReviewCard = styled(Card)({
  backgroundColor: "#ffffff",
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", // Subtle shadow for depth
  borderRadius: "8px",
  padding: "20px",
  margin: "1rem",
  maxWidth: "350px",
  textAlign: "center",
  transition: "transform 0.3s ease",
  "&:hover": {
    transform: "scale(1.05)",
  },
});

const StarRating = styled(Box)({
  display: "flex",
  justifyContent: "center",
  marginTop: "0.5rem",
  color: "#ffb400", // Gold color for stars
});

const Reviews = () => {
  return (
    <ReviewsContainer>
      {/* Title */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          width: "100%",
          padding: "20px",
          textAlign: "right", // Aligns text to the right
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontWeight: "bold",
            color: "black",
            textTransform: "uppercase",
            display: "flex",
            alignItems: "center",
          }}
        >
          <ArrowDownwardIcon
            sx={{
              transform: "rotate(45deg)",
              fontSize: "30px",
              marginRight: "8px",
            }}
          />
          Trusted by Clients
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          gap: 2,
        }}
      >
        {reviewsData.map((review) => (
          <ReviewCard key={review.id}>
            <Avatar
              src={review.avatar}
              alt={review.name}
              sx={{ width: 64, height: 64, margin: "0 auto" }}
            />
            <Typography variant="h6" sx={{ mt: 2 }}>
              {review.name}
            </Typography>
            <StarRating>
              {Array(review.rating)
                .fill()
                .map((_, index) => (
                  <StarIcon key={index} />
                ))}
            </StarRating>
            <CardContent>
              <Typography variant="body2" color="textSecondary">
                {review.review}
              </Typography>
            </CardContent>
          </ReviewCard>
        ))}
      </Box>
    </ReviewsContainer>
  );
};

export default Reviews;
