import React from "react";
import { Box, Typography } from "@mui/material";
import CustomSlider from "./thirdElementComponents./CustomSlider";
import Skills from "./thirdElementComponents./Skills";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { useLanguage } from "../constants/LanguageContext"; // Import language context
import { secondText } from "../constants/textCostants"; // Import translations

const Second = () => {
  const { language } = useLanguage(); // Get current language
  const text = secondText[language] || secondText.en; // Fallback to English if language not found

  return (
    <Box
      sx={{
        width: "100%",
        height: "auto",
        paddingBlock: "100px",
        background: "linear-gradient(135deg, #e0e0e0, #d3d3d3)",
      }}
    >
      <Box
        sx={{
          maxWidth: {
            xs: "90%",
            md: "80%",
          },
          margin: "auto",
        }}
      >
        {/* Title */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-start",
            position: "relative",
            padding: "20px 0 20px 0",
          }}
        >
          <ArrowDownwardIcon
            sx={{
              transform: "rotate(45deg)",
              fontSize: "30px",
              marginRight: "8px",
            }}
          />
          <Typography
            variant="h6"
            sx={{
              fontWeight: "bold",
              color: "black",
              textTransform: "uppercase",
            }}
          >
            {text.title}
          </Typography>
        </Box>

        {/* Content Section */}
        <Box
          sx={{
            display: "flex",
            width: "100%",
            height: "auto",
            background: "linear-gradient(135deg, #e0e0e0, #d3d3d3)",
            flexDirection: { xs: "column", lg: "row" },
            justifyContent: { xs: "flex-start", md: "space-around" },
            alignItems: { xs: "center", md: "flex-start" },
            padding: { xs: 0, md: 1 },
          }}
        >
          {/* Column 1 */}
          <CustomSlider />

          {/* Column 2 */}
          <Box
            sx={{
              flex: 1,
              width: "100%",
              padding: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography gutterBottom>{text.paragraph1}</Typography>
            <Typography gutterBottom>{text.paragraph2}</Typography>
            <Skills />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Second;
