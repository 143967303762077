import React from 'react';
import { Box, Typography, Divider } from '@mui/material';
import HeroSimple from './HeroSimple';
import InfoIcon from '@mui/icons-material/Info';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import BusinessIcon from '@mui/icons-material/Business';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { useLanguage } from "../constants/LanguageContext";
import { simpleText } from "../constants/textCostants";

const Simple = () => {
  const { language } = useLanguage();
  const text = simpleText[language] || simpleText.en;

  return (
    <>
      <HeroSimple />
      <Box
        sx={{
          width: '100%',
          maxWidth: '800px',
          margin: '0 auto',
          padding: '100px 20px',
          textAlign: 'left',
          fontFamily: 'Montserrat, sans-serif',
          lineHeight: '1.7',
        }}
      >
        <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 3, display: 'flex', alignItems: 'center', gap: 1 }}>
          <InfoIcon color="primary" /> {text.title}
        </Typography>

        <Typography variant="body1" sx={{ mb: 4 }}>
          {text.intro}
        </Typography>

        <Divider sx={{ my: 3 }} />

        <Typography variant="h5" sx={{ fontWeight: 'bold', display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
          <CheckCircleIcon color="secondary" /> {text.benefitsTitle}
        </Typography>
        <Typography variant="body1" sx={{ mb: 3 }}>
          {text.benefitsDesc}
        </Typography>

        <Divider sx={{ my: 3 }} />

        <Typography variant="h5" sx={{ fontWeight: 'bold', display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
          <CheckCircleIcon color="secondary" /> {text.featuresTitle}
        </Typography>
        <Box sx={{ pl: 2 }}>
          {text.features.map((feature, index) => (
            <Typography key={index} variant="body1" sx={{ mb: 2 }}>
              {feature}
            </Typography>
          ))}
        </Box>

        <Divider sx={{ my: 3 }} />

        <Typography variant="h5" sx={{ fontWeight: 'bold', display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
          <BusinessIcon color="primary" /> {text.whoTitle}
        </Typography>
        <Box sx={{ pl: 2 }}>
          {text.whoBenefits.map((benefit, index) => (
            <Typography key={index} variant="body1" sx={{ mb: 2 }}>
              {benefit}
            </Typography>
          ))}
        </Box>

        <Divider sx={{ my: 3 }} />

        <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2, display: 'flex', alignItems: 'center', gap: 1 }}>
          <MonetizationOnIcon color="primary" /> {text.pricingTitle}
        </Typography>
        <Typography variant="body1" sx={{ mb: 3 }}>
          {text.pricingDesc}
        </Typography>

        <Divider sx={{ my: 3 }} />

        <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2, display: 'flex', alignItems: 'center', gap: 1 }}>
          <CheckCircleIcon color="primary" /> {text.simplicityTitle}
        </Typography>
        <Typography variant="body1" sx={{ mb: 3 }}>
          {text.simplicityDesc}
        </Typography>

        <Typography variant="h5" sx={{ fontWeight: 'bold', my: 4, textAlign: 'center' }}>
          {text.closing}
        </Typography>
      </Box>
    </>
  );
};

export default Simple;
