import React from "react";
import { Box, Typography, Divider } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import BusinessIcon from "@mui/icons-material/Business";
import PublicIcon from "@mui/icons-material/Public";
import HeroSeo from "./HeroSeo";
import { useLanguage } from "../constants/LanguageContext";
import { seo } from "../constants/textCostants";

const Seo = () => {
  const { language } = useLanguage();
  const text = seo[language] || seo.en;

  const keywordMap = {
    en: [
      "Keywords",
      "Title Tags and Meta Descriptions",
      "Headers",
      "Images and Alt Text",
      "Mobile Optimization",
      "Internal Linking",
      "Page Speed",
      "Backlinks",
      "Social Media",
      "Local Listings",
      "Guest Blogging",
      "Influencer Collaborations",
    ],
    sv: [
      "Nyckelord",
      "Titel taggar och Meta Beskrivningar",
      "Rubriker",
      "Bilder och Alt-text",
      "Mobiloptimering",
      "Internlänkning",
      "Sidans hastighet",
      "Backlinks",
      "Sociala Medier",
      "Lokala kataloger",
      "Gästbloggande",
      "Influencersamarbeten",
    ],
    ru: [
      "Ключевые слова",
      "Теги заголовка и мета-описания",
      "Заголовки",
      "Изображения и Alt-текст",
      "Оптимизация для мобильных",
      "Внутренние ссылки",
      "Скорость загрузки",
      "Обратные ссылки",
      "Социальные сети",
      "Локальные каталоги",
      "Гостевые посты",
      "Сотрудничество с инфлюенсерами",
    ],
    uk: [
      "Ключові слова",
      "Теги заголовка і мета-описи",
      "Заголовки",
      "Зображення та Alt-текст",
      "Оптимізація для мобільних",
      "Внутрішні посилання",
      "Швидкість сторінки",
      "Зворотні посилання",
      "Соціальні медіа",
      "Локальні каталоги",
      "Гостьові блоги",
      "Співпраця з інфлюенсерами",
    ],
    da: [
      "Søgeord",
      "Titel-tags og Meta Beskrivelser",
      "Overskrifter",
      "Billeder og Alt-tekst",
      "Mobiloptimering",
      "Intern links",
      "Sidehastighed",
      "Backlinks",
      "Sociale Medier",
      "Lokale kataloger",
      "Gæsteblogging",
      "Influencer-samarbejder",
    ],
  };

  const boldKeywords = (text, lang) => {
    const keywords = keywordMap[lang] || keywordMap.en;
    const regex = new RegExp(`(${keywords.join("|")})`, "gi");

    return text.split(regex).map((part, index) =>
      keywords.includes(part) ? (
        <strong key={index} style={{ color: "#4e4d4d" }}>
          {part}
        </strong>
      ) : (
        part
      )
    );
  };

  return (
    <>
      <HeroSeo />
      <Box
        sx={{
          width: "100%",
          maxWidth: "800px",
          margin: "0 auto",
          padding: "100px 20px",
          textAlign: "left",
          fontFamily: "Montserrat, sans-serif",
          lineHeight: "1.7",
        }}
      >
        <Typography
          variant="h4"
          sx={{
            fontWeight: "bold",
            mb: 3,
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <InfoIcon color="primary" /> {text.title}
        </Typography>

        <Typography variant="body1" sx={{ mb: 4 }}>
          {text.intro}
        </Typography>

        <Divider sx={{ my: 3 }} />

        <Typography
          variant="h5"
          sx={{
            fontWeight: "bold",
            display: "flex",
            alignItems: "center",
            gap: 1,
            mb: 2,
          }}
        >
          <CheckCircleIcon color="secondary" /> {text.onSiteTitle}
        </Typography>
        <Box sx={{ pl: 2 }}>
          {text.onSitePoints.map((point, index) => (
            <Typography key={index} variant="body1" sx={{ mb: 2 }}>
              • {boldKeywords(point, language)}
            </Typography>
          ))}
        </Box>

        <Divider sx={{ my: 3 }} />

        <Typography
          variant="h5"
          sx={{
            fontWeight: "bold",
            display: "flex",
            alignItems: "center",
            gap: 1,
            mb: 2,
          }}
        >
          <PublicIcon color="primary" /> {text.offSiteTitle}
        </Typography>
        <Box sx={{ pl: 2 }}>
          {text.offSitePoints.map((point, index) => (
            <Typography key={index} variant="body1" sx={{ mb: 2 }}>
              • {boldKeywords(point, language)}
            </Typography>
          ))}
        </Box>

        <Divider sx={{ my: 3 }} />

        <Typography
          variant="h5"
          sx={{
            fontWeight: "bold",
            mb: 2,
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <BusinessIcon color="primary" /> {text.customVsCmsTitle}
        </Typography>
        <Typography variant="body1" sx={{ mb: 3 }}>
          {text.customVsCmsDesc}
        </Typography>

        <Divider sx={{ my: 3 }} />

        <Typography
          variant="h5"
          sx={{ fontWeight: "bold", my: 4, textAlign: "center" }}
        >
          {text.closing}
        </Typography>
      </Box>
    </>
  );
};

export default Seo;
