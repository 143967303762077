import React from 'react';
import { Box, Typography, Divider } from '@mui/material';
import HeroDynamic from './HeroDynamic';
import UpdateIcon from '@mui/icons-material/Update';
import BuildIcon from '@mui/icons-material/Build';
import ExpandIcon from '@mui/icons-material/Expand';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { dynamicText } from '../../components/constants/textCostants';
import { useLanguage } from "../constants/LanguageContext";

const Dynamic = () => {
  const { language } = useLanguage();
  const text = dynamicText[language] || dynamicText.en;

  return (
    <>
      <HeroDynamic />
      <Box
        sx={{
          width: '100%',
          maxWidth: '800px',
          margin: '0 auto',
          padding: ' 100px 20px',
          textAlign: 'left',
          fontFamily: 'Montserrat, sans-serif',
          lineHeight: '1.7',
        }}
      >
        <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 3, display: 'flex', alignItems: 'center', gap: 1 }}>
          <UpdateIcon color="primary" /> {text.title}
        </Typography>
        
        <Typography variant="body1" sx={{ mb: 4 }}>
          {text.intro}
        </Typography>

        <Divider sx={{ my: 3 }} />

        <Typography variant="h5" sx={{ fontWeight: 'bold', display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
          <BuildIcon color="secondary" /> {text.benefitsTitle}
        </Typography>
        <Box sx={{ pl: 2 }}>
          {text.benefits.map((benefit, index) => (
            <Typography
              key={index}
              variant="body1"
              sx={{ mb: 2 }}
            >
              {benefit}
            </Typography>
          ))}
        </Box>

        <Divider sx={{ my: 3 }} />

        <Typography variant="h5" sx={{ fontWeight: 'bold', display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
          <ExpandIcon color="primary" /> {text.suitabilityTitle}
        </Typography>
        <Typography variant="body1" sx={{ mb: 3 }}>
          {text.suitabilityDesc}
        </Typography>

        <Divider sx={{ my: 3 }} />

        <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2, display: 'flex', alignItems: 'center', gap: 1 }}>
          <MonetizationOnIcon color="primary" /> {text.pricingTitle}
        </Typography>
        <Typography variant="body1" sx={{ mb: 3 }}>
          {text.pricingDesc.part1}
          <strong style={{ color: '#333536' }}>{text.pricingDesc.strong1}</strong>
          {text.pricingDesc.part2}
          <strong style={{ color: '#333536' }}>{text.pricingDesc.strong2}</strong>
          {text.pricingDesc.part3}
        </Typography>

        <Typography variant="h5" sx={{ fontWeight: 'bold', my: 4, textAlign: 'center' }}>
          {text.closing}
        </Typography>
      </Box>
    </>
  );
};

export default Dynamic;

