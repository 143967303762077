import React, { useState } from 'react';
import { Box, TextField, Button, Typography } from '@mui/material';
import emailjs from 'emailjs-com';

// Define environment variables at the top
const SERVICE_ID = process.env.REACT_APP_SERVICEID;
const TEMPLATE_ID = process.env.REACT_APP_TEMPLATEID;
const USER_ID = process.env.REACT_APP_USERID;


const Contact = () => {
  const [formValues, setFormValues] = useState({
    name: '',
    email: '',
    description: '',
  });

  const [formErrors, setFormErrors] = useState({
    name: false,
    email: false,
    description: false,
  });

  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = {
      name: formValues.name === '',
      email: formValues.email === '',
      description: formValues.description === '',
    };
    setFormErrors(errors);
  
    if (!Object.values(errors).includes(true)) {
      // Sending email through emailjs
      emailjs.send(
        SERVICE_ID,
        TEMPLATE_ID,
        {
          from_name: formValues.name,
          from_email: formValues.email,
          message: formValues.description,
        },
        USER_ID
      )
        .then((result) => {
          console.log(result.text);
          setSuccessMessage('Message successfully sent!');
          setErrorMessage('');
          setFormValues({ name: '', email: '', description: '' }); // Clear the fields here
        })
        .catch((error) => {
          console.error(error.text);
          setErrorMessage('Failed to send the message. Please try again.');
          setSuccessMessage('');
        });
    }
  };

  return (
    <Box
      sx={{
        width: { xs: '80%', md: '50%' },
        margin: 'auto',
        padding: 2,
        textAlign: 'center',
      }}
    >
      <Typography variant="h6" gutterBottom sx={{ color: 'white' }}>
        Would like to contact me?
      </Typography>

      <form onSubmit={handleSubmit}>
        <TextField
          label="Name"
          name="name"
          variant="standard"
          fullWidth
          required
          margin="normal"
          value={formValues.name}
          onChange={handleChange}
          error={formErrors.name}
          helperText={formErrors.name ? 'Name is required' : ''}
          slotProps={{
            inputLabel: {
              style: { color: 'white' }, // White label color
            },
          }}
          sx={{
            input: { color: 'white' }, // White text color
            '& .MuiInput-underline:before': {
              borderBottomColor: 'white', // White bottom border
            },
            '& .MuiInput-underline:hover:before': {
              borderBottomColor: 'white !important', // Keep white bottom border on hover
            },
            '& .MuiInput-underline:after': {
              borderBottomColor: 'white', // White bottom border on focus
            },
          }}
        />
        <TextField
          label="Email"
          name="email"
          variant="standard"
          fullWidth
          required
          margin="normal"
          value={formValues.email}
          onChange={handleChange}
          error={formErrors.email}
          helperText={formErrors.email ? 'Email is required' : ''}
          slotProps={{
            inputLabel: {
              style: { color: 'white' }, // White label color
            },
          }}
          sx={{
            input: { color: 'white' }, // White text color
            '& .MuiInput-underline:before': {
              borderBottomColor: 'white', // White bottom border
            },
            '& .MuiInput-underline:hover:before': {
              borderBottomColor: 'white !important', // Keep white bottom border on hover
            },
            '& .MuiInput-underline:after': {
              borderBottomColor: 'white', // White bottom border on focus
            },
          }}
        />
        <TextField
          label="Message"
          name="description"
          variant="standard"
          fullWidth
          required
          margin="normal"
          multiline
          rows={4}
          value={formValues.description}
          onChange={handleChange}
          error={formErrors.description}
          helperText={formErrors.description ? 'Description is required' : ''}
          slotProps={{
            inputLabel: {
              style: { color: 'white' }, // White label color
            },
          }}
          sx={{
            textarea: { color: 'white' }, // White text color for multiline
            '& .MuiInput-underline:before': {
              borderBottomColor: 'white', // White bottom border
            },
            '& .MuiInput-underline:hover:before': {
              borderBottomColor: 'white !important', // Keep white bottom border on hover
            },
            '& .MuiInput-underline:after': {
              borderBottomColor: 'white', // White bottom border on focus
            },
          }}
        />
   <Button
  type="submit"
  variant="contained"
  sx={{
    marginTop: 2,
    backgroundColor: '#4e4d4d', // Gray color
    color: 'white', // Set the text color
    '&:hover': {
      backgroundColor: '#7e7e7e', 
      color: 'black', 
    },
  }}
>
  Submit
</Button>
      </form>

      {/* Display success or error messages */}
      {successMessage && (
        <Typography sx={{ color: 'green', marginTop: 2 }}>{successMessage}</Typography>
      )}
      {errorMessage && (
        <Typography sx={{ color: 'red', marginTop: 2 }}>{errorMessage}</Typography>
      )}
    </Box>
  );
};

export default Contact;
