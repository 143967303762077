import { MenuItem, Select, Box } from "@mui/material";
import { GrLanguage } from "react-icons/gr";
import ReactCountryFlag from "react-country-flag";
import { useLanguage } from "../constants/LanguageContext"; // Import your context

function LanguageSwitcher() {
  const { language, switchLanguage } = useLanguage(); // Get the language and switch function from context

  const handleChange = (event) => {
    switchLanguage(event.target.value); // Use the context method to change language
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center", color: "white",  }}>
      <Select
        value={language}
        onChange={handleChange}
        variant="outlined"
        size="small"
        sx={{ color: "white", borderColor: "white", display: "flex", alignItems: "center" }}
        alt="Hög kvalitet och snabb leverans av anpassad hemsida för företag i Stockholm"
        renderValue={() => {
          switch (language) {
            case "en":
              return <ReactCountryFlag countryCode="GB" svg style={{ width: "20px", height: "20px"}} alt="Hög kvalitet och snabb leverans av anpassad hemsida för företag i Stockholm" />;
            case "sv":
              return <ReactCountryFlag countryCode="SE" svg style={{ width: "20px", height: "20px" }} alt="Hög kvalitet och snabb leverans av anpassad hemsida för företag i Stockholm" />;
            case "ru":
              return <ReactCountryFlag countryCode="RU" svg style={{ width: "20px", height: "20px" }} alt="Hög kvalitet och snabb leverans av anpassad hemsida för företag i Stockholm" />;
            case "uk":
              return <ReactCountryFlag countryCode="UA" svg style={{ width: "20px", height: "20px" }} alt="Hög kvalitet och snabb leverans av anpassad hemsida för företag i Stockholm" />;
            case "da":
              return <ReactCountryFlag countryCode="DK" svg style={{ width: "20px", height: "20px" }} alt="Hög kvalitet och snabb leverans av anpassad hemsida för företag i Stockholm" />;
            default:
              return <GrLanguage alt="Hög kvalitet och snabb leverans av anpassad hemsida för företag i Stockholm" />;
          }
        }}
      >
        <MenuItem value="en">
          <ReactCountryFlag countryCode="GB" svg style={{ width: "20px", height: "20px", marginRight: "8px" }} />
          English
        </MenuItem>
        <MenuItem value="sv">
          <ReactCountryFlag countryCode="SE" svg style={{ width: "20px", height: "20px", marginRight: "8px" }} />
          Svenska
        </MenuItem>
        <MenuItem value="ru">
          <ReactCountryFlag countryCode="RU" svg style={{ width: "20px", height: "20px", marginRight: "8px" }} />
          Russian
        </MenuItem>
        <MenuItem value="uk">
          <ReactCountryFlag countryCode="UA" svg style={{ width: "20px", height: "20px", marginRight: "8px" }} />
          Ukrainian
        </MenuItem>
        <MenuItem value="da">
          <ReactCountryFlag countryCode="DK" svg style={{ width: "20px", height: "20px", marginRight: "8px" }} />
          Danish
        </MenuItem>
      </Select>
    </Box>
  );
}

export default LanguageSwitcher;
