


import React, { createContext, useContext, useState, useEffect } from 'react';

// Create a Language Context
const LanguageContext = createContext();

// Provider component
export const LanguageProvider = ({ children }) => {
  // Initialize language from localStorage or default to 'sv'
  const [language, setLanguage] = useState(localStorage.getItem('language') || 'sv'); 

  // Function to switch the language and save it to localStorage
  const switchLanguage = (newLanguage) => {
    setLanguage(newLanguage);
    localStorage.setItem('language', newLanguage); // Persist the selected language
  };

  // If localStorage already contains a language value, update the state on mount
  useEffect(() => {
    const storedLanguage = localStorage.getItem('language');
    if (storedLanguage) {
      setLanguage(storedLanguage);
    }
  }, []); // Runs only on mount

  return (
    <LanguageContext.Provider value={{ language, switchLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

// Custom hook to use the Language Context
export const useLanguage = () => useContext(LanguageContext);
