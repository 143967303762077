import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Box,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Collapse,
  Menu,
  MenuItem,
  Link,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router-dom";
import { useMediaQuery, useTheme } from "@mui/material";
import { useLanguage } from "../constants/LanguageContext";
import { navbar } from "../constants/textCostants";
import LanguageSwitcher from "../constants/LanguageSwitcher";

function NavbarFixed() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [blogAnchorEl, setBlogAnchorEl] = useState(null);
  const [expanded, setExpanded] = useState(null);
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const navigate = useNavigate();

  const { language } = useLanguage();
  const navText = navbar[language] || navbar.sv;

  const toggleMenu = () => {
    setMenuOpen((prev) => !prev);
  };

  const handleBlogClick = (event) => {
    setBlogAnchorEl(event.currentTarget);
  };

  const handleBlogClose = () => {
    setBlogAnchorEl(null);
  };

  const handleExpandClick = (id) => {
    setExpanded(expanded === id ? null : id);
  };

  const scrollToTopAndNavigate = (path) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    navigate(path);
    setMenuOpen(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          paddingTop: "3px",
          backgroundColor: scrolled ? "#413f3f" : "transparent",
          boxShadow: "none",
          transition: "background-color 0.3s ease",
        }}
      >
        <Toolbar sx={{ justifyContent: "space-between", width: "100%" }}>
          <Link
            href="/"
            underline="none"
            color="inherit"
            sx={{
              textDecoration: "none",
              "&:hover": { color: "grey.600" },
            }}
          >
            <Box>
              <Typography
                variant="h6"
                sx={{ fontSize: { xs: "13px", md: "1.2rem" }, fontWeight: 400 }}
              >
                {navText.title}
              </Typography>
              <Typography
                variant="h6"
                sx={{ fontSize: { xs: "1rem", md: "1.2rem" }, fontWeight: 700 }}
              >
                {navText.subtitle}
              </Typography>
            </Box>
          </Link>

          <Box sx={{ display: "flex", alignItems: "center", gap: "1px" }}>
            <LanguageSwitcher />

            {isMdUp ? (
              <Box sx={{ display: "flex", gap: "2rem" }}>
                {/* Home Link */}
                <Typography variant="h6">
                  <Box
                    component="span"
                    onClick={() => scrollToTopAndNavigate("/")}
                    sx={{
                      textDecoration: "none",
                      color: "white",
                      cursor: "pointer",
                    }}
                    aria-label={`Go to ${navText.home} page`}
                  >
                    {navText.home}
                  </Box>
                </Typography>

                {/* Customer Case Link */}
                <Typography variant="h6">
                  <Box
                    component="span"
                    onClick={() => scrollToTopAndNavigate("/customer-case")}
                    sx={{
                      textDecoration: "none",
                      color: "white",
                      cursor: "pointer",
                    }}
                    aria-label={`Go to ${navText.customerCase} page`}
                  >
                    {navText.customerCase}
                  </Box>
                </Typography>

                {/* Blog Link */}
                <Typography variant="h6">
                  <Box
                    onClick={handleBlogClick}
                    sx={{
                      textDecoration: "none",
                      color: "white",
                      cursor: "pointer",
                    }}
                    aria-label="Open Blog submenu"
                  >
                    {navText.blog}
                  </Box>
                </Typography>

                {/* FAQ Link */}
                <Typography variant="h6">
                  <Box
                    component="span"
                    onClick={() => scrollToTopAndNavigate("/faq")}
                    sx={{
                      textDecoration: "none",
                      color: "white",
                      cursor: "pointer",
                    }}
                    aria-label={`Go to ${navText.faq} page`}
                  >
                    {navText.faq}
                  </Box>
                </Typography>

                <Menu
                  anchorEl={blogAnchorEl}
                  open={Boolean(blogAnchorEl)}
                  onClose={handleBlogClose}
                >
                  <MenuItem onClick={() => scrollToTopAndNavigate("/blog")}>
                    {navText.blogHome}
                  </MenuItem>
                  <MenuItem
                    onClick={() => scrollToTopAndNavigate("/blog/simple")}
                  >
                    {navText.simple}
                  </MenuItem>
                  <MenuItem
                    onClick={() => scrollToTopAndNavigate("/blog/dynamic")}
                  >
                    {navText.dynamic}
                  </MenuItem>
                  <MenuItem
                    onClick={() => scrollToTopAndNavigate("/blog/ecommerce")}
                  >
                    {navText.ecommerce}
                  </MenuItem>
                  <MenuItem onClick={() => scrollToTopAndNavigate("/blog/seo")}>
                    {navText.seo}
                  </MenuItem>
                </Menu>
              </Box>
            ) : (
              <IconButton
                edge="end"
                color="inherit"
                aria-label="Open menu"
                sx={{ color: "white" }}
                onClick={toggleMenu}
              >
                <MenuIcon />
              </IconButton>
            )}
          </Box>
        </Toolbar>
      </AppBar>

      {/* Drawer for mobile view */}
      <Drawer
        anchor="top"
        open={menuOpen}
        onClose={toggleMenu}
        PaperProps={{
          sx: {
            backgroundColor: "#413f3f",
            color: "white",
            height: "100vh",
            padding: "2rem",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 4,
          }}
        >
          <Typography variant="h6">(X)</Typography>
          <Typography
            variant="h6"
            onClick={toggleMenu}
            sx={{ cursor: "pointer" }}
          >
            CLOSE
          </Typography>
        </Box>
        <List sx={{ textAlign: "left" }}>
          {/* Home Link */}
          <ListItem
            sx={{
              justifyContent: "flex-start",
              py: 1,
              borderBottom: "1px solid white",
            }}
          >
            <ListItemText
              primary={<Typography variant="h6">{navText.home}</Typography>}
              onClick={() => scrollToTopAndNavigate("/")}
            />
          </ListItem>

          {/* Customer Case Link */}
          <ListItem
            sx={{
              justifyContent: "flex-start",
              py: 1,
              borderBottom: "1px solid white",
            }}
          >
            <ListItemText
              primary={
                <Typography variant="h6">{navText.customerCase}</Typography>
              }
              onClick={() => scrollToTopAndNavigate("/customer-case")}
            />
          </ListItem>

          {/* Blog Link with Expand/Collapse */}
          <ListItem
            button
            onClick={() => handleExpandClick("blog")}
            sx={{
              justifyContent: "flex-start",
              py: 1,
              borderBottom: "1px solid white",
            }}
          >
            <ListItemText
              primary={<Typography variant="h6">{navText.blog}</Typography>}
            />
            {expanded === "blog" ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={expanded === "blog"} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {["blogHome", "simple", "dynamic", "ecommerce", "seo"].map(
                (subtext) => (
                  <ListItem
                    key={subtext}
                    sx={{ pl: 4 }}
                    onClick={() =>
                      scrollToTopAndNavigate(
                        subtext === "blogHome"
                          ? "/blog"
                          : `/blog/${subtext.toLowerCase()}`
                      )
                    }
                  >
                    <ListItemText
                      primary={
                        <Typography variant="h6">{navText[subtext]}</Typography>
                      }
                    />
                  </ListItem>
                )
              )}
            </List>
          </Collapse>

          {/* FAQ Link */}
          <ListItem
            sx={{
              justifyContent: "flex-start",
              py: 1,
              borderBottom: "1px solid white",
            }}
          >
            <ListItemText
              primary={<Typography variant="h6">{navText.faq}</Typography>}
              onClick={() => scrollToTopAndNavigate("/faq")}
            />
          </ListItem>
        </List>
      </Drawer>
    </>
  );
}

export default NavbarFixed;
