import React, { useState } from "react";
import { Box, Typography, IconButton } from "@mui/material";
import Weather from "../components/widget/Weather";
import NavbarFixed from "../components/navbar/NavbarFixed";
import { AiOutlineLinkedin, AiOutlineGithub } from "react-icons/ai";
import { Fade } from "react-awesome-reveal";
import backgroundImage from "../assets/44.jpg";

const Hero = () => {
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);

  // Function to handle when the video has loaded
  const handleVideoLoad = () => {
    setIsVideoLoaded(true);
  };

  return (
    <Box
      sx={{
        position: "relative",
        height: "60vh",
        overflow: "hidden",
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      {/* Add NavbarFixed */}
      <NavbarFixed />

      {/* Background video */}
      <video
        key="second-video"
        className="background-video"
        autoPlay
        muted
        playsInline
        loop
        preload="auto"
        onLoadedData={handleVideoLoad}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          objectFit: "cover",
          opacity: isVideoLoaded ? 1 : 0,
          transition: "opacity 3s ease",
        }}
      >
        <source src={require("../assets/bg6.webm")} type="video/webm" />
        Your browser does not support the video tag.
      </video>

      <Fade delay={500} duration={1000}>
        <Box
          sx={{
            position: "absolute",
            top: "30%",
            left: "10%",
            color: "rgb(231, 230, 228)",
            letterSpacing: "3px",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <Typography
            sx={{
              fontFamily: "'Playfair Display', serif",
              mb: -1,
              fontSize: { xs: "2rem", md: "3rem" },
            }}
          >
            Alina
          </Typography>
          <Typography
            sx={{
              fontFamily: "'Montserrat', sans-serif",
              fontWeight: 900,
              fontSize: { xs: "2rem", md: "3rem" },
            }}
          >
            SAMOTEEV
          </Typography>
          <Typography
            variant="h5"
            sx={{
              fontFamily: "'Montserrat', sans-serif",
              fontWeight: 300,
              fontSize: "1.2rem",
            }}
          >
            Full-stack Developer
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" }, // Vertical on xs, horizontal on sm and above
              justifyContent: "center",
              gap: { xs: "none", sm: "1rem" },
              paddingTop: "1rem",
            }}
          >
            <IconButton
              component="a"
              href="https://www.linkedin.com/in/alina-samoteev-627836a5/"
              target="_blank"
              rel="noreferrer"
              sx={{ color: "rgb(161, 159, 159)" }}
              aria-label="LinkedIn Profile"
            >
              <AiOutlineLinkedin size="40px" />
            </IconButton>
            <IconButton
              component="a"
              href="https://github.com/AlinaCGM"
              target="_blank"
              rel="noreferrer"
              sx={{ color: "rgb(161, 159, 159)" }}
              aria-label="GitHub Profile"
            >
              <AiOutlineGithub size="40px" />
            </IconButton>
          </Box>
        </Box>
      </Fade>

      <Box
        sx={{
          position: "absolute",
          bottom: "5%",
          right: "5px",
          width: { md: "325px" },
        }}
      >
        <Weather />
      </Box>
    </Box>
  );
};

export default Hero;
