import React, { useState } from "react";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  Pagination,
  PaginationItem,
} from "@mui/material";
import HeroCustomer from "../components/customerCase/HeroCustomer";

// Import images for each project
import img1 from "../assets/projects/1.jpg";
import img2 from "../assets/projects/2.jpeg";
import img3 from "../assets/projects/3.jpeg";
import img4 from "../assets/projects/5.png";
import img5 from "../assets/projects/6.jpeg";
import img6 from "../assets/projects/7.jpg";
import img7 from "../assets/projects/8.jpg";
import img8 from "../assets/projects/alleconomi.jpg";
import img9 from "../assets/projects/grcbygg.se.jpg";
import img10 from "../assets/projects/rent-stad.jpg";
import img11 from "../assets/projects/shoutitout.se.jpg";
import { Link } from "react-router-dom";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

// Define an array of project data
const projectData = [
  {
    id: 1,
    title: "Allekonomi",
    img: img8,
    path: "/customer-case/allekonomi",
  },
  {
    id: 2,
    title: "Aglo Redovisning",
    img: img1,
    path: "/customer-case/agloredovisning",
  },
  {
    id: 3,
    title: "Beauty Space",
    img: img3,
    path: "/customer-case/beauty-space",
  },
  {
    id: 4,
    title: "Shout It Out",
    img: img11,
    path: "/customer-case/shoutitout",
  },
  {
    id: 5,
    title: "SV Rent",
    img: img2,
    path: "/customer-case/svrent",
  },
  {
    id: 6,
    title: "Project 6",
    img: img6,
    path: "/customer-case/allekonomi",
  },
  {
    id: 7,
    title: "Project 7",
    img: img7,
    path: "/customer-case/allekonomi",
  },
  {
    id: 8,
    title: "Project 8",
    img: img5,
    path: "/customer-case/allekonomi",
  },
  {
    id: 9,
    title: "Project 9",
    img: img9,
    path: "/customer-case/allekonomi",
  },
  {
    id: 10,
    title: "Project 10",
    img: img10,
    path: "/customer-case/allekonomi",
  },
  {
    id: 11,
    title: "Project 11",
    img: img4,
    path: "/customer-case/allekonomi",
  },
];

const itemsPerPage = 4;

const CustomerCase = () => {
  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = projectData.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <>
      <HeroCustomer />
      <Box
        sx={{
          padding: 4,
          textAlign: "center",
          maxWidth: "1000px",
          margin: "0 auto",
          paddingBlock: "100px",
        }}
      >
        {/* Title */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-start",
            position: "relative",
            padding: "20px 0 0 0",
          }}
        >
          <ArrowDownwardIcon
            sx={{
              transform: "rotate(45deg)",
              fontSize: "30px",
              marginRight: "8px",
            }}
          />
          <Typography
            variant="h6"
            sx={{
              fontWeight: "bold",
              color: "black",
              textTransform: "uppercase",
            }}
          >
            Inspireras av våra kunder
          </Typography>
        </Box>

        {/* Responsive card layout using flexbox */}
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: 4,
            justifyContent: "center",
            paddingBlock: "50px",
          }}
        >
          {currentItems.map((project) => (
            <Box
              key={project.id}
              sx={{
                flex: "1 1 100%",
                maxWidth: "100%",
                "@media (min-width: 600px)": {
                  flex: "1 1 48%",
                },
              }}
            >
              <Box
                sx={{
                  padding: "40px 0 0 40px",
                  backgroundColor: "#E4EBF5",
                  borderRadius: "12px",
                }}
              >
                <Card
                  sx={{
                    boxShadow: 3,
                    transition: "transform 0.3s",
                    "&:hover": { transform: "scale(1.05)" },
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    backgroundColor: "#f9f9f9",
                    borderRadius: "5px",
                    overflow: "hidden",
                    gap: 5,
                  }}
                >
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      image={project.img}
                      alt={project.title}
                      sx={{
                        width: "100%",
                        height: "400px",
                        objectFit: "cover",
                        marginBottom: 2,
                        objectPosition: "top",
                      }}
                    />
                  </CardActionArea>
                </Card>
                <CardContent sx={{ padding: 2, paddingTop: 2 }}>
                  <Typography
                    variant="h6"
                    textAlign={"left"}
                    sx={{ fontWeight: "bold" }}
                  >
                    {project.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    textAlign={"left"}
                    color="text.secondary"
                    sx={{ mt: 1 }}
                  >
                    {project.description}
                  </Typography>
                  <Link
                    to={project.path}
                    onClick={() => window.scrollTo(0, 0)}
                    textAlign={"left"}
                    underline="hover"
                    sx={{
                      display: "block",
                      mt: 1,
                      color: "primary.main",
                      fontWeight: 500,
                    }}
                  >
                    Läs kundcaset →
                  </Link>
                </CardContent>
              </Box>
            </Box>
          ))}
        </Box>

        {/* Pagination Controls */}
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <Pagination
            count={Math.ceil(projectData.length / itemsPerPage)}
            page={currentPage}
            onChange={handlePageChange}
            variant="outlined"
            shape="rounded"
            renderItem={(item) => (
              <PaginationItem
                {...item}
                sx={{
                  borderRadius: "4px",
                  borderColor:
                    item.page === currentPage ? "primary.main" : "grey.300",
                  backgroundColor:
                    item.page === currentPage ? "primary.main" : "transparent",
                  color: item.page === currentPage ? "white" : "inherit",
                }}
              />
            )}
          />
        </Box>
      </Box>
    </>
  );
};

export default CustomerCase;
