import React from 'react';
import { Box, Typography } from '@mui/material';
import imagePlaceholder from '../../assets/proj/faq.jpg';

const HeroFaq = () => {
  return (
    <Box
      sx={{
        width: '100%',
        height: '400px', // Adjust height as needed
        backgroundImage: `url(${imagePlaceholder})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {/* Overlay */}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
          zIndex: 1,
        }}
      />

      {/* Text Content */}
      <Box
        sx={{
          position: 'relative',
          zIndex: 2,
          textAlign: 'center',
          color: 'white',
          padding: '20px',
          fontFamily: 'Montserrat, sans-serif',
        }}
      >
       <Typography variant="h2" sx={{ fontWeight: 'bold', fontSize: '36px', mb: 2 }}>
  FIND ANSWERS 🌟
</Typography>
<Typography variant="h5" sx={{ fontSize: '20px', fontWeight: '500' }}>
  Your Questions Answered to Make Informed Decisions
</Typography>

      </Box>
    </Box>
  );
};

export default HeroFaq;
