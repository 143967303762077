import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Import your images
import image1 from "../../../assets/proj/all6.png";
import image2 from "../../../assets/proj/all1.png";
import image3 from "../../../assets/projects/alleconomi.jpg";
import image4 from "../../../assets/proj/image 21.png";
import image5 from "../../../assets/proj/all5.png";
import image6 from "../../../assets/proj/image 25.png";
import image7 from "../../../assets/shout/shoutitout.se.jpg";
import image8 from "../../../assets/proj/image26.png";
import { Box } from "@mui/material";

const images = [image1, image2, image3, image4, image5, image6, image7, image8];

const CustomSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 100,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
  };

  return (
    <Box
      sx={{
        position: "relative",
        width: { xs: "80%", lg: "50%" },
        height: { xs: "40vh", md: "70vh" },
        overflow: "hidden",
        margin: "0 auto  ",
        backgroundColor: "white",
      }}
    >
      <Slider {...settings}>
        {images.map((imgSrc, index) => (
          <div key={index}>
            <img
              src={imgSrc}
              alt={`Slide ${index + 1}`}
              style={{ width: "100%", height: "auto" }}
            />
          </div>
        ))}
      </Slider>
      {/* <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(56, 53, 53, 0.8)",
          zIndex: 1,
          pointerEvents: "none", // Allow clicks to pass through to the slider
        }}
      /> */}
    </Box>
  );
};

export default CustomSlider;
